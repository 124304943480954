export default [
  {
    /* [ 0 ] */
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "From",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    cols: 6,
  },
  {
    /* [ 1 ] */
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "To",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    cols: 6,
  },
  {
    /* [ 2 ] */
    type: "select",
    margin: true,
    showLabel: true,
    label: "Result",
    model: null,
    options: [
      {
        id: 0,
        label: "ALL",
      },
      {
        id: 1,
        label: "Approved",
      },
      {
        id: 2,
        label: "Declined",
      },
      {
        id: 3,
        label: "Error",
      },
      {
        id: 4,
        label: "Held for Review",
      },
    ],
    reduce: "id",
    selectText: "label",
    cols: 12,
  },
  {
    /* [ 2 ] */
    type: "select",
    margin: true,
    showLabel: true,
    label: "Program",
    model: null,
    options: [
      {
        id: 0,
        name: "All",
      },
    ],
    reduce: "id",
    selectText: "name",
    cols: 12,
  },
  {
    /* [ 2 ] */
    type: "select",
    margin: true,
    showLabel: true,
    label: "Advisor",
    model: null,
    options: [
      {
        id: 0,
        user_name: "All",
      },
    ],
    reduce: "id",
    selectText: "user_name",
    cols: 12,
  },
]

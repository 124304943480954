export default [
    {
        // [0]
        key: "transaction_id",
        label: "Transaction",
        tdClass: "pt-1",
        visible: true,
    },
    {
        // [1]
        key: "merchant",
        label: "Merchant",
        tdClass: "pt-1",
        visible: true,
    },
    {
        // [2]
        key: "client_name",
        label: "Client",
        tdClass: "pt-1",
        visible: true,
    },
    {
        // [3]
        key: "account",
        label: "Account",
        tdClass: "pt-1",
        visible: true,
    },
    {
        // [4]
        key: "program",
        label: "Program",
        tdClass: "pt-1",
        visible: true,
    },
    {
        // [5]
        key: "advisor_name",
        label: "Advisor",
        tdClass: "pt-1",
        visible: true,
    },
    {
        // [6]
        key: "amount",
        label: "Amount",
        sortable: true,
        thClass: "text-center",
        tdClass: "pt-1 text-center",
        formatter: (value) => {
            return `$ ${value}`;
        },
        visible: true,
    },
    {
        // [7]
        key: "card_number",
        label: "Credit Card",
        tdClass: "pt-1",
        visible: true,
    },
    {
        // [8]
        key: "status",
        label: "Result",
        tdClass: "pt-1",
        visible: true,
    },
    {
        // [9]
        key: "created_at",
        label: "Submit Date/Time/User",
        thClass: "text-center",
        tdClass: "pt-1 text-center",
        visible: true,
    },
   
]

<template>
  <div>
    <!-- Tab 2 hace referencia al type_transaction = 2 (Manual Card) -->
    <automatic-manual-table v-if="$route.name == 'adm-register-payments-manual'" :tab="2" :fields="fields" :filters="filters" />
    <router-view v-if="['adm-history-imports', 'adm-manual-process-payment'].includes($route.name)" />
  </div>
</template>

<script>
import fields from "@/views/administration/views/register-payment/data/manual.fields";
import filters from "@/views/administration/views/register-payment/data/manual.filters";
import AutomaticManualTable from '@/views/administration/views/register-payment/components/AutomaticManualTable.vue';
export default {
    components:{
        AutomaticManualTable
    },
    data(){
        return {
            fields,
            filters
        }
    }
}
</script>

<style>

</style>